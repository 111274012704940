<template>
  <div class="bg-mafia-dark flex-column position-relative">
		<div class="container">
			<div class="row">
				<div class="logo_top mt-200 mb-50">
					<img :src="require('@/assets/image/logo_60.png')" alt="마피아">
					<div class="text_guide pt-10">
						<h4>MAFIA에<br>
							관심 있는 분야를 선택해주세요.</h4>
					</div>
				</div>
				<ul class="interest_list">
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400001"
								type="checkbox" id="music">
							<label for="music">
								<strong>Music</strong>
								<p>음악을 좋아하고 관심을 가지고 있습니다.</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400002"
								type="checkbox" id="art">
							<label for="art">
								<strong>Art</strong>
								<p>예술, 디자인 등에 관심을 가지고 있습니다.</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400003"
								type="checkbox" id="fashion">
							<label for="fashion">
								<strong>Fashion</strong>
								<p>패션 디자인, 모델 등에 관심을 가지고 있습니다.</p>
							</label>
						</div>
					</li>
					<li>
						<div class="dark_checks interest_area">
							<input
								v-model="item.fav"
								value="CM00400004"
								type="checkbox" id="influence">
							<label for="influence">
								<strong>Influence</strong>
								<p>유튜버, 블로거 등을 운영하는 것에 관심을 가지고 있습니다.</p>
							</label>
						</div>
					</li>
				</ul>
			</div>
		</div>

    <div class="intro_btn_wrap">
      <div class="btn_area">
        <button class="btn_l btn_fill_blue" @click="toNext">다음</button>
      </div>
    </div>
	</div>
</template>

<script>

	export default {
		name: 'mafia0101'
		,components: {},
		props: ['Axios']
		, data: function(){
			return {
				program: {
					name: '관심분야'
					, not_header: true
					, not_footer: true
          , type: 'auth'
				}
				,item: {
					fav: []
				}
				, items_fav: []
			}
		}
		,methods:{
			toNext: async  function(){
				try{

				if(!this.item.fav.length){
					throw '관심분야를 선택하세요'
				}

				let data = []
				this.item.fav.forEach((v) => data.push({ concern_sphere_code: v}))

				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_concernsphere
					, data: {
						concern_sphere_list: data
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('to', { name: 'nice'})
				}else{
					if(result.code == 'E000400016'){
						this.$bus.$emit('to', { name: 'nice'})
					}else if(result.code == 'E000400043'){
						this.$bus.$emit('to', { name: 'sms'})
					}else{
						throw result.message
					}
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>
<style>
.dark_checks input[type="checkbox"] + label {
  /*display: inline-block;*/
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  display: block;
  text-align: left;
  padding-left: 5rem;
}

.dark_checks input[type="checkbox"] + label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 24px;
  height: 24px;
  background-color: var(--bg-Blue01);
  border: 1px solid var(--line-white01);
  border-radius: 50%;
  content: "\e908";
  box-sizing: border-box;
  font-size: 0;
}


img {
  width: 50px;
  height: 50px;
}




/* button */

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }

button { background-color: transparent; }
.btn_l {
  display: inline-block;
  height: 3.5rem;
  line-height: 1rem;
  min-width: 9rem;
  padding: 0 3rem;
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  box-sizing: border-box;
}

.flex-column {
  justify-content: space-between;
}

.text_guide > h4 {
  color : white
}

</style>